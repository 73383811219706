import React, { Component } from 'react';
import '../styles/App.css'

export class Home extends Component {
  static displayName = Home.name;

  render() {
      return (
          <div class="container-block">
              <h1>Coming Soon</h1>
              <p>Come back again later...  &#9203;</p>
          </div>
    );
  }
}